import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import { projects } from "../../data/projects";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <Container>
      <h1>DeltaZero</h1>
      Willkommen auf meiner Website!
      <br />
      <br />
      <h3>Über mich</h3>
      Ich bin zurzeit dualer Informatik Student, wobei ich zwischen Leipzig und Schwerin tätig bin. Ich interessiere mich für Informatik, Mathematik und Physik.
      <br />
      <br />
      Weitere Informationen, sowie Social-Media-Profile findest du <Link to={"/about"}>hier</Link>.
      <br />
      <br />
      <h3>Meine Projekte</h3>
      <Container fluid>
        <Row className="g-4">
          {projects.map((project) => (
            <Col key={project.title} xs={12} sm={6} md={4} lg={3}>
              <Card className="h-100">
                {project.image !== undefined && <Card.Img variant="top" src={project.image} />}
                <Card.Body>
                  <Card.Title>{project.title}</Card.Title>
                  {project.subtitle && (
                    <i>
                      <Card.Subtitle className="mb-1">{project.subtitle}</Card.Subtitle>
                    </i>
                  )}
                  <Card.Text>{project.description}</Card.Text>
                </Card.Body>
                <Card.Footer as={Stack} direction="horizontal" gap={1}>
                  {project.skills.map((skill) => (
                    <skill.Icon key={skill.name} />
                  ))}
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};
