import { getAge } from "../helper/age";
import { Skill, skills } from "./skill";

export const age = getAge(new Date("2/22/2005"));

export interface SkillPointType {
  skill: Skill;
  points: number;
}

export const skillPoints: SkillPointType[] = [
  { skill: skills.Python, points: 9 },
  { skill: skills.HTML, points: 7 },
  { skill: skills.CSS, points: 4 },
  { skill: skills.Javascript, points: 8 },
  { skill: skills.Nodejs, points: 8 },
  { skill: skills.Typescript, points: 7 },
  { skill: skills.React, points: 7 },
];

export const languages = [
  { name: "Deutsch", level: "C2 (Muttersprache)" },
  { name: "Englisch", level: "B2" },
  { name: "Französisch", level: "A1" },
];

export const interests = ["Programmieren", "Mathematik", "Physik"];

export const cv = [
  { period: "2011-2015", station: "Grundschule Pädagogium Schwerin" },
  { period: "2015-2017", station: "Orientierungsstufe Pädagogium Schwerin" },
  { period: "2017-2023", station: "Gymnasium Pädagogium Schwerin" },
  { period: "2023-Jetzt", station: "Duales Studium beim DVZ M-V und der BA Leipzig" },
];
