import { Card, Container, Stack, Table } from "react-bootstrap";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { SocialLink } from "../../components/SocialLink";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { SkillPoints } from "../../components/SkillPoints/SkillPoints";
import { LanguageLevels } from "../../components/LanguageLevels/LanguageLevels";
import { age, cv, interests, languages, skillPoints } from "../../data/about";
import "./About.sass";
import { CVEntry } from "../../components/CVEntry/CVEntry";
import { ExternalLink } from "../../components/ExternalLink/ExternalLink";

export const About = () => {
  return (
    <Container fluid="md">
      <Card>
        <Card.Body>
          <Card.Title>Über mich</Card.Title>
          <Card.Text>
            Ich bin Noah Kruggel, {age} Jahre alt. Ich studiere dual an der
            <ExternalLink url="https://www.dhsn.de/leipzig" name="DHSN Leipzig" />
            mit dem <ExternalLink url="https://www.dvz-mv.de" name="DVZ M-V" /> als Praxispartner.
          </Card.Text>
          <Stack direction="horizontal" gap={2}>
            <SocialLink icon={faGithub} url="https://github.com/DeItaZero" />
            <SocialLink icon={faLinkedin} url="https://www.linkedin.com/in/noah-kruggel/" />
            <SocialLink icon={faEnvelope} url="mailto:kruggelnoah@gmail.com" />
          </Stack>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th colSpan={3}>
                  <Card.Title>Skills</Card.Title>
                </th>
              </tr>
            </thead>
            <tbody>
              {skillPoints.map((skillPoint) => (
                <SkillPoints skillPoint={skillPoint} key={skillPoint.skill.name} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>
                  <Card.Title>Sprachkenntnisse</Card.Title>
                </th>
              </tr>
            </thead>
            <tbody>
              {languages.map((props) => (
                <LanguageLevels {...props} key={props.name} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>
                  <Card.Title>Hobbies / Interressen</Card.Title>
                </th>
              </tr>
            </thead>
            <tbody>
              {interests.map((name) => (
                <tr key={name}>
                  <td>{name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>
                  <Card.Title>Lebenslauf</Card.Title>
                </th>
              </tr>
            </thead>
            <tbody>
              {cv.map((props) => (
                <CVEntry {...props} key={props.station} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};
