import { Css3Original, Html5Original, JavascriptOriginal, NodejsOriginal, PythonOriginal, ReactOriginal, TypescriptOriginal } from "devicons-react";
import { FunctionComponent } from "react";
import { IconProps } from "react-bootstrap-icons";

export interface Skill {
  name: string;
  Icon: FunctionComponent<IconProps>;
}

export const skills: Record<string, Skill> = {
  Python: { name: "Python", Icon: PythonOriginal },
  HTML: { name: "HTML", Icon: Html5Original },
  CSS: { name: "CSS", Icon: Css3Original },
  Javascript: { name: "Javascript", Icon: JavascriptOriginal },
  Nodejs: { name: "Node.js", Icon: NodejsOriginal },
  Typescript: { name: "Typescript", Icon: TypescriptOriginal },
  React: { name: "React", Icon: ReactOriginal },
};
