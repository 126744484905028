import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import { useAuth } from "contexts/AuthContext";

export const CustomNavbar = () => {
  const { user } = useAuth();

  const projectLinks = [
    ["/timetable", "Stundenplan"],
    ["/menu", "Speiseplan"],
    ["/ruler", "Massband"],
    ["/attendance", "Anwesenheit"],
    // ["/shopping-list", "Einkaufsliste"],
  ] as [string, string][];

  return (
    <Navbar bg="dark" data-bs-theme="dark" fixed="top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img alt="Δ0" src={logo} width="30" height="30" className="d-inline-block align-top" />
          <div className="domain">&nbsp;DeItaZero.de</div>
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/about">
            Über mich
          </Nav.Link>
          <NavDropdown title="Meine Projekte">
            {projectLinks.map(([endpoint, name]) => (
              <NavDropdown.Item key={endpoint} as={Link} to={endpoint}>
                {name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
        {user && (
          <Navbar.Brand as={Link} to="/login" className="user">
            <img alt="user" src={user.picture} width="30" height="30" className="d-inline-block align-top user-profile" />
            <div className="username">&nbsp;{user.name}</div>
          </Navbar.Brand>
        )}
      </Container>
    </Navbar>
  );
};
