import { Skill, skills } from "./skill";
import deitazerode from "@assets/images/deitazero.jpg";
import discordbots from "@assets/images/discordbots.jpg";
import python from "@assets/images/python.jpg";

export interface Project {
  title: string;
  subtitle?: string;
  description: string;
  skills: Skill[];
  image?: string;
}

export const projects: Project[] = [
  {
    title: "DeItaZero.de",
    subtitle: "Meine eigene Website",
    description: "Auf dieser Website kann man den Stunden- und Speiseplan einsehen und auf Bildern Objekte ausmessen.",
    skills: [skills.React, skills.Nodejs, skills.Typescript],
    image: deitazerode,
  },
  {
    title: "Discord Bots",
    subtitle: "Bots für die Plattform Discord",
    description: "Ich habe Bots für die Plattform Discord entwickelt. Mit diesen kann man sich mit Minispielen die Zeit vertreiben.",
    skills: [skills.Python, skills.Javascript, skills.Typescript],
    image: discordbots,
  },
  { title: "Python Scripts", description: "Scripts, mit denen ich meist mathematische Probleme löse.", skills: [skills.Python], image: python },
];
