import { faCircle as faCircleFilled } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "react-bootstrap";
import { SkillPointType } from "../../data/about";
import "./SkillPoints.sass";

export const SkillPoints = ({ skillPoint }: { skillPoint: SkillPointType }) => {
  return (
    <tr>
      <td className="skill-icon">
        <skillPoint.skill.Icon size="1.5em" />
      </td>
      <td className="skill-name">
        <h5 className="mb-0">{skillPoint.skill.name}</h5>
      </td>
      <td className="skill-points">
        <Stack direction="horizontal" gap={1}>
          {[...new Array(skillPoint.points).keys()].map((point) => (
            <FontAwesomeIcon icon={faCircleFilled} key={point}></FontAwesomeIcon>
          ))}
          {[...new Array(10 - skillPoint.points).keys()].map((point) => (
            <FontAwesomeIcon icon={faCircle} key={point}></FontAwesomeIcon>
          ))}
        </Stack>
      </td>
    </tr>
  );
};
